import call from "../../../shared/service/http";
import BoardContants from "@/packages/board/BoardContants";

export default {
    namespaced: true,
    state: {
        boardMembers: [],
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data;
        },
    },
    getters: {
        BoardGetter: (state) => (setup) => state[setup],
    },
    actions: {
        /**
         *
         * @param commit
         */
        boardMembers({commit}) {
            call('get', BoardContants.BOARD)
                .then(res=> {
                    if (res.data.success) {
                        commit("MUTATE", {
                            state: "boardMembers",
                            data: res.data.data,
                        });
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        },
        /**
         *
         * @param placeholder
         * @param payload
         */
        board({dispatch}, payload) {
            call('post', BoardContants.BOARD, payload)
                .then(res=> {
                    if (res.data.success) {
                        Event.$emit("ApiSuccess", res.data.message)
                        dispatch('boardMembers')
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        }
    }
}
