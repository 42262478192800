import PartnerStore from "@/packages/partner/PartnerStore";
import PartnerLinks from "@/packages/partner/PartnerLinks";
import PartnerRoutes from "@/packages/partner/PartnerRoutes";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...PartnerRoutes)
        }
        if (options.store) {
            options.store.registerModule('Partner', PartnerStore)
            options.store.commit('Dashboard/SET_LINKS', PartnerLinks)
        }
    }
}
