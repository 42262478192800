<template>
  <v-container fluid>
    <!--BEGIN Dialog-->
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Contacts</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isValid" ref="contactForm">
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Company Name*"
                        required
                        v-model="formData.company_name"
                        :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="P.O Box*"
                        required
                        v-model="formData.address"
                        :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Physical Address"
                        :rules="[rules.required]"
                        v-model="formData.physical_address"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Street"
                        :rules="[rules.required]"
                        v-model="formData.street"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="City"
                        :rules="[rules.required]"
                        v-model="formData.city"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Phone"
                        v-model="formData.company_phone"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Email*"
                        v-model="formData.company_email"
                        :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--END Dialog-->
    <v-card flat>
      <v-card-title>
        <v-spacer/>
        <v-btn @click="dialog=true" class="primary"><v-icon>mdi-plus</v-icon>Add Contact</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="contacts"
        >
          <template v-slot:item.ID="{index}">
            <span>{{index+1}}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "ContactCard",
  beforeRouteEnter(to,from,next){
    next(v=>{
      v.$store.dispatch('Contact/contacts');
    })
  },
  data: () => ({
    dialog: false,
    isValid: false,
    formData:{
      company_name:"",
      physical_address:"",
      address:"",
      street:"",
      city:"",
      company_phone:"",
      company_email:""
    },
    rules:{
      required: value => !!value || 'Required!',
    },
    headers: [
      {
        text: '#S/N',
        align: '',
        sortable: true,
        value: 'ID'
      },
      {
        text: 'Name',
        align: '',
        sortable: true,
        value: 'company_name'
      },
      {
        text: 'Email',
        align: '',
        sortable: true,
        value: 'company_email'
      },
      {
        text: 'Phone',
        align: '',
        sortable: true,
        value: 'company_phone'
      },
      {
        text: 'Physical Address',
        align: '',
        sortable: true,
        value: 'physical_address'
      },
      {
        text: 'P.O BOX',
        align: '',
        sortable: true,
        value: 'address'
      },
      {
        text: 'Street',
        align: '',
        sortable: true,
        value: 'street'
      },
      {
        text: "City",
        align: '',
        sortable: true,
        value: 'city'
      }
    ],
  }),
  computed: {
    contacts(){
      return this.$store.getters['Contact/ContactGetter']("contacts")
    }
  },
  methods: {
    save() {
      if (!this.isValid) {
        this.$refs.contactForm.validate()
      }
      else{
        this.$store.dispatch('Contact/contact', { ...this.formData});
      }
    },
  }
}
</script>

<style scoped>

</style>
