import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import TeamCard from "@/packages/team/components/TeamCard";
import Team from "@/packages/team/view/Team";
import {Auth} from "@/packages/auth";

const TeamRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: Team,
                children: [
                    {
                        path: 'team',
                        component: TeamCard,
                        name: "FocusAreaCard",
                        middleware: { middleware: [Auth]}
                    }
                ]
            }
        ]
    }
];
export default TeamRoutes;
