import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import BoardCard from "@/packages/board/components/BoardCard";
import Board from "@/packages/board/view/Board";
import {Auth} from "@/packages/auth";

const BoardRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: Board,
                children: [
                    {
                        path: 'board',
                        component: BoardCard,
                        name: "BoardCard",
                        meta: { middleware: [Auth]}
                    }
                ]
            }
        ]
    }
];
export default BoardRoutes;
