<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-card>
              <v-card-text>
                <div>
                  <h3>Focus Areas</h3>
                </div>
                <p class="pt-5">{{ focusAreas }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-card>
              <v-card-text>
                <div>
                  <h3>Services</h3>
                </div>
                <p class="pt-5">{{services}}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-card>
              <v-card-text>
                <div>
                  <h3>Teams</h3>
                </div>
                <p class="pt-5">{{teams}}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-card>
              <v-card-text>
                <div>
                  <h3>Publications</h3>
                </div>
                <p class="pt-5">{{ publications }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-card>
              <v-card-text>
                <div>
                  <h3>Board Members</h3>
                </div>
                <p class="pt-5">{{boardMembers}}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-card>
              <v-card-text>
                <div>
                  <h3>Contacts</h3>
                </div>
                <p class="pt-5">{{contacts}}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "DashboardCard",
  beforeRouteEnter(to,from,next){
    next(v=>{
      v.$store.dispatch('Contact/contacts');
      v.$store.dispatch('Board/boardMembers');
      v.$store.dispatch('Team/teams');
      v.$store.dispatch('FocusArea/focusAreas');
      v.$store.dispatch('Publication/publications');
      v.$store.dispatch('Service/services');
    })
  },
  computed:{
    contacts(){
      return this.$store.getters['Contact/ContactGetter']("contacts").length
    },
    boardMembers(){
      return this.$store.getters['Board/BoardGetter']("boardMembers").length
    },
    teams(){
      return this.$store.getters['Team/TeamGetter']("teams").length
    },
    services(){
      return this.$store.getters['Service/ServiceGetter']("services").length
    },
    publications(){
      return this.$store.getters['Publication/PublicationGetter']("publications").length
    },
    focusAreas(){
      return this.$store.getters['FocusArea/FocusAreaGetter']("focusAreas").length
    },
  }
}
</script>
