import PublicationStore from "@/packages/publications/PublicationStore";
import PublicationLinks from "@/packages/publications/PublicationLinks";
import PublicationRoutes from "@/packages/publications/PublicationRoutes";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...PublicationRoutes)
        }
        if (options.store) {
            options.store.registerModule('Publication', PublicationStore)
            options.store.commit('Dashboard/SET_LINKS', PublicationLinks)
        }
    }
}
