import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import PublicationCard from "@/packages/publications/components/PublicationCard";
import Publication from "@/packages/publications/view/Publication";
import {Auth} from "@/packages/auth";

const PublicationRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: Publication,
                children: [
                    {
                        path: 'publications',
                        component: PublicationCard,
                        name: "PublicationCard",
                        meta: { middleware: [Auth]}
                    }
                ]
            }
        ]
    }
];
export default PublicationRoutes;
