<template>
  <router-view/>
</template>

<script>
export default {
  name: "FocusArea"
}
</script>

<style scoped>

</style>
