import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import ServiceCard from "@/packages/company-services/components/ServiceCard";
import Service from "@/packages/company-services/view/Service";
import {Auth} from "@/packages/auth";

const ServiceRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: Service,
                children: [
                    {
                        path: 'services',
                        component: ServiceCard,
                        name: "ServiceCard",
                        meta: { middleware: [Auth]}
                    }
                ]
            }
        ]
    }
];
export default ServiceRoutes;
