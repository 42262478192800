import FocusAreaRoutes from "@/packages/focus-areas/FocusAreaRoutes";
import FocusAreasStore from "@/packages/focus-areas/FocusAreasStore";
import FocusAreasLinks from "@/packages/focus-areas/FocusAreasLinks";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...FocusAreaRoutes)
        }
        if (options.store) {
            options.store.registerModule('FocusArea', FocusAreasStore)
            options.store.commit('Dashboard/SET_LINKS', FocusAreasLinks)
        }
    }
}
