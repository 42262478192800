import ServiceStore from "@/packages/company-services/ServiceStore";
import ServiceLinks from "@/packages/company-services/ServiceLinks";
import ServiceRoutes from "@/packages/company-services/ServiceRoutes";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...ServiceRoutes)
        }
        if (options.store) {
            options.store.registerModule('Service', ServiceStore)
            options.store.commit('Dashboard/SET_LINKS', ServiceLinks)
        }
    }
}
