import call from "../../../shared/service/http";
import PublicationContants from "@/packages/publications/PublicationContants";

export default {
    namespaced: true,
    state: {
        publications: [],
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data;
        },
    },
    getters: {
        PublicationGetter: (state) => (setup) => state[setup],
    },
    actions: {
        /**
         *
         * @param commit
         */
        publications({commit}) {
            call('get', PublicationContants.PUBLICATION)
                .then(res=> {
                    if (res.data.success) {
                        commit("MUTATE", {
                            state: "publications",
                            data: res.data.data,
                        });
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        },
        /**
         *
         * @param placeholder
         * @param payload
         */
        publication({dispatch}, payload) {
            call('post', PublicationContants.PUBLICATION, payload)
                .then(res=> {
                    if (res.data.success) {
                        Event.$emit("ApiSuccess", res.data.message)
                        dispatch('publications')
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        }
    }
}
