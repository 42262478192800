import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import {Auth} from "@/packages/auth";
import PartnerCard from "@/packages/partner/components/PartnerCard";

const PartnerRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: 'partners',
                component: PartnerCard,
                name: "PartnerCard",
                meta: { middleware: [Auth]}
            }
        ]
    }
];
export default PartnerRoutes;
