<template>
  <v-container fluid>
    <!--BEGIN Dialog-->
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add New Focus Area</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isValid" ref="focusAreaForm">
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Title*"
                        required
                        v-model="formData.title"
                        :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-file-input
                        label="Upload Attachment"
                        :rules="[rules.required]"
                        @change="uploadImage"
                    />
                  </v-col>
                  <v-col cols="12">
                    <span>Description</span>
                    <VueEditor v-model="formData.description" :rules="[rules.required]"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--END Dialog-->
    <v-card flat>
      <v-card-title>
        <v-spacer/>
        <v-btn @click="dialog=true" class="primary"><v-icon>mdi-plus</v-icon>Add Focus Area</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="focusAreas"
        >
          <template v-slot:item.ID="{index}">
            <span>{{index+1}}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import helpers from "../../../../shared/utilities/helpers";

export default {
  name: "FocusAreaCard",
  components: { VueEditor },
  beforeRouteEnter(to,from,next){
    next(v=>{
      v.$store.dispatch('FocusArea/focusAreas');
    })
  },
  data: () => ({
    isValid: false,
    dialog: false,
    formData:{
      title:"",
      description:"",
      attachment: "",
    },
    rules: {
      required: value => !!value || 'Required!',
    },
    headers: [
      {
        text: '#S/N',
        align: '',
        sortable: true,
        value: 'ID'
      },
      {
        text: 'Title',
        align: '',
        sortable: true,
        value: 'title'
      },
      {
        text: 'Description',
        align: '',
        sortable: true,
        value: 'description'
      }
    ],
  }),
  computed:{
    focusAreas(){
      return this.$store.getters['FocusArea/FocusAreaGetter']("focusAreas")
    }
  },
  methods: {
    async uploadImage(fileInput){
      this.formData.attachment=await helpers.uploadImage(fileInput);
    },
    save() {
      this.formData.description=helpers.removeTags(this.formData.description);
      if (!this.isValid) {
        this.$refs.focusAreaForm.validate()
      }
      else{
        this.$store.dispatch('FocusArea/focusArea', { ...this.formData});
        this.dialog=false
      }
    },
  }
}
</script>

<style scoped>

</style>
