import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import {Auth} from "@/packages/auth";
import ProjectCard from "@/packages/project/components/ProjectCard";

const ProjectRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: 'projects',
                component: ProjectCard,
                name: "ProjectCard",
                meta: { middleware: [Auth]}
            }
        ]
    }
];
export default ProjectRoutes;
