import call from "../../../shared/service/http";
import ServiceContants from "@/packages/company-services/ServiceContants";

export default {
    namespaced: true,
    state: {
        services: [],
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data;
        },
    },
    getters: {
        ServiceGetter: (state) => (setup) => state[setup],
    },
    actions: {
        /**
         *
         * @param commit
         */
        services({commit}) {
            call('get', ServiceContants.SERVICE)
                .then(res=> {
                    if (res.data.success) {
                        commit("MUTATE", {
                            state: "services",
                            data: res.data.data,
                        });
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        },
        /**
         *
         * @param placeholder
         * @param payload
         */
        service({dispatch}, payload) {
            call('post', ServiceContants.SERVICE, payload)
                .then(res=> {
                    if (res.data.success) {
                        Event.$emit("ApiSuccess", res.data.message)
                        dispatch('services')
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        }
    }
}
