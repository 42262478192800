import TeamStore from "@/packages/team/TeamStore";
import TeamLinks from "@/packages/team/TeamLinks";
import TeamRoutes from "@/packages/team/TeamRoutes";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...TeamRoutes)
        }
        if (options.store) {
            options.store.registerModule('Team', TeamStore)
            options.store.commit('Dashboard/SET_LINKS', TeamLinks)
        }
    }
}
