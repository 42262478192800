<template>
  <v-container fluid>
    <!--BEGIN Dialog-->
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Service</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isValid" ref="serviceForm">
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        label="Title*"
                        required
                        v-model="formData.title"
                        :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <span>Description</span>
                    <VueEditor :rules="[rules.required]" v-model="formData.description"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--END Dialog-->
    <v-card flat>
      <v-card-title>
        <v-spacer/>
        <v-btn @click="dialog=true" class="primary"><v-icon>mdi-plus</v-icon>Add Service</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="services"
        >
          <template v-slot:item.ID="{index}">
            <span>{{index+1}}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import helpers from "../../../../shared/utilities/helpers";
export default {
  name: "ServiceCard",
  components: { VueEditor },
  beforeRouteEnter(to,from,next){
    next(v=>{
      v.$store.dispatch('Service/services');
    })
  },
  data: () => ({
    isValid: false,
    dialog: false,
    formData:{
      title: '',
      description: '',
    },
    rules:{
      required: value => !!value || 'Required!',
    },
    headers: [
      {
        text: '#S/N',
        align: '',
        sortable: true,
        value: 'ID'
      },
      {
        text: 'Title',
        align: '',
        sortable: true,
        value: 'title'
      },
      {
        text: 'Description',
        align: '',
        sortable: true,
        value: 'description'
      }
    ],
  }),
  computed: {
    services(){
      return this.$store.getters['Service/ServiceGetter']("services")
    }
  },
  methods: {
    save() {
      this.formData.description=helpers.removeTags(this.formData.description);
      if (!this.isValid) {
        this.$refs.serviceForm.validate()
      }
      else{
        this.$store.dispatch('Service/service', { ...this.formData});
      }
    },
  }
}
</script>

<style scoped>

</style>
