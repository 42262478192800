import BoardStore from "@/packages/board/BoardStore";
import BoardLinks from "@/packages/board/BoardLinks";
import BoardRoutes from "@/packages/board/BoardRoutes";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...BoardRoutes)
        }
        if (options.store) {
            options.store.registerModule('Board', BoardStore)
            options.store.commit('Dashboard/SET_LINKS', BoardLinks)
        }
    }
}
