import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import confirmation from "@/plugins/confirmation";
import store from './store'
import loader from "@/plugins/loader";
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueCookies from 'vue-cookies'
import { VueTypedJs } from 'vue-typed-js'

//packages
import Dashboard from "@/packages/dashboard";
import FocusArea from "@/packages/focus-areas";
import CompanyServices from "@/packages/company-services";
import Team from "@/packages/team";
import Board from "@/packages/board";
import Publications from "@/packages/publications";
import Contact from "@/packages/contact";
import toastr from "../shared/toastr";
import Project from "@/packages/project";
import Partner from "@/packages/partner";

Vue.config.productionTip = false
window.Event = new Vue();
Vue.use(loader);
Vue.use(Toast);
Vue.use(VueTypedJs)
Vue.use(confirmation)

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: '7d'})
const options = {
  store,
  router,
}
Vue.use(Dashboard, options)
Vue.use(FocusArea, options)
Vue.use(CompanyServices, options)
Vue.use(Team, options)
Vue.use(Board, options)
Vue.use(Publications, options)
Vue.use(Project, options)
Vue.use(Partner, options)
Vue.use(Contact, options)
new Vue({
  router,
  vuetify,
  store,
  mixins: [toastr],
  render: h => h(App)
}).$mount('#app')
