import ContactLinks from "@/packages/contact/ContactLinks";
import ContactStore from "@/packages/contact/ContactStore";
import ContactRoutes from "@/packages/contact/ContactRoutes";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...ContactRoutes)
        }
        if (options.store) {
            options.store.registerModule('Contact', ContactStore)
            options.store.commit('Dashboard/SET_LINKS', ContactLinks)
        }
    }
}
