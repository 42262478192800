import ProjectRoutes from "@/packages/project/ProjectRoutes";
import ProjectStore from "@/packages/project/ProjectStore";
import ProjectLinks from "@/packages/project/ProjectLinks";

export default {
    install(Vue, options) {
        if (options.router) {
            options.router.addRoute(...ProjectRoutes)
        }
        if (options.store) {
            options.store.registerModule('Project', ProjectStore)
            options.store.commit('Dashboard/SET_LINKS', ProjectLinks)
        }
    }
}
