class Helpers {
    uploadImage(fileInput) {
        if (fileInput) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(fileInput)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        }
        else {
            // do nothing
        }
    }
    removeTags(str) {
        return str.replace(/(<([^>]+)>)/ig, '')
    }
}
export default new Helpers()
