<template>
  <v-container fluid>
    <!--BEGIN Dialog-->
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Team Members</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isValid" ref="boardForm">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="First Name*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.first_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Last Name*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.last_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Email*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Phone Number*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.phone_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Title*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        label="Role*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.role"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-file-input
                        label="Upload Photo"
                        :rules="[rules.required]"
                        @change="uploadImage"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <v-text-field
                        label="Designation*"
                        required
                        :rules="[rules.required]"
                        v-model="formData.designation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <span>Short Bio</span>
                    <VueEditor :rules="[rules.required]" v-model="formData.bio"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--END Dialog-->
    <v-card flat>
      <v-card-title>
        <v-spacer/>
        <v-btn @click="dialog=true" class="primary"><v-icon>mdi-plus</v-icon>Add Team Members</v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="teams"
        >
          <template v-slot:item.ID="{index}">
            <span>{{index+1}}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {VueEditor} from 'vue2-editor';
import helpers from "../../../../shared/utilities/helpers";
export default {
  name: "TeamCard",
  components: { VueEditor},
  beforeRouteEnter(to,from,next){
    next(v=>{
      v.$store.dispatch('Team/teams');
    })
  },
  data: () => ({
    isValid: false,
    isImageSaved: false,
    imageError:"",
    dialog: false,
    formData: {
      first_name: "",
      last_name: "",
      email:"",
      phone_number: "",
      title:"",
      designation:"",
      role:"",
      attachment: "",
      bio: ""
    },
    rules: {
      required: value => !!value || 'Required!',
    },
    headers: [
      {
        text: '#S/N',
        align: '',
        sortable: true,
        value: 'ID'
      },
      {
        text: 'Picture',
        align: '',
        sortable: true,
        value: 'avatar_url'
      },
      {
        text: 'First Name',
        align: '',
        sortable: true,
        value: 'first_name'
      },
      {
        text: 'Last Name',
        align: '',
        sortable: true,
        value: 'last_name'
      },
      {
        text: 'Phone Number',
        align: '',
        sortable: true,
        value: 'phone_number'
      },
      {
        text: 'Email',
        align: '',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Title',
        align: '',
        sortable: true,
        value: 'title'
      },
      {
        text: 'Role',
        align: '',
        sortable: true,
        value: 'role'
      },
    ],
  }),
  computed:{
    teams(){
      return this.$store.getters['Team/TeamGetter']("teams")
    }
  },
  methods: {
    async uploadImage(fileInput){
      this.formData.attachment=await helpers.uploadImage(fileInput);
    },
    save() {
      this.formData.bio=helpers.removeTags(this.formData.bio);
      if (!this.isValid) {
        this.$refs.boardForm.validate()
      }
      else{
        this.$store.dispatch('Team/team', { ...this.formData});
      }
    },
  }
}
</script>

<style scoped>

</style>
