import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import FocusAreas from "@/packages/focus-areas/view/FocusArea";
import FocusAreaCard from "@/packages/focus-areas/components/FocusAreaCard";
import {Auth} from "@/packages/auth";

const FocusAreaRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: FocusAreas,
                children: [
                    {
                        path: 'focus-areas',
                        component: FocusAreaCard,
                        name: "FocusAreaCard",
                        meta: { middleware: [Auth]}
                    }
                ]
            }
        ]
    }
];
export default FocusAreaRoutes;
