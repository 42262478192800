import call from "../../../shared/service/http";
import ContactContants from "@/packages/contact/ContactContants";

export default {
    namespaced: true,
    state: {
        contacts: [],
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data;
        },
    },
    getters: {
        ContactGetter: (state) => (setup) => state[setup],
    },
    actions: {
        /**
         *
         * @param commit
         */
        contacts({commit}) {
            call('get', ContactContants.CONTACT)
                .then(res=> {
                    if (res.data.success) {
                        commit("MUTATE", {
                            state: "contacts",
                            data: res.data.data,
                        });
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        },
        /**
         *
         * @param placeholder
         * @param payload
         */
        contact({dispatch}, payload) {
            call('post', ContactContants.CONTACT, payload)
                .then(res=> {
                    if (res.data.success) {
                        Event.$emit("ApiSuccess", res.data.message)
                        dispatch('contacts')
                    }
                    else{
                        Event.$emit("ApiError", 'Something went wrong');
                    }
                })
                .catch(err=>{
                    Event.$emit("ApiError", err.response.data.message);
                })
        }
    }
}
