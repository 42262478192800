<template>
  <v-container v-if="loading" class="spinner">
    <div class="loader">
      <v-img class="image" src="https://kinetics.co.ke/wp-content/uploads/2021/06/cropped-icon-270x270.jpg"></v-img>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Spinner",
  computed: {
    loading() {
      return this.$store.getters.loading;
    }
  },
};
</script>

<style scoped>
.spinner{
  width: 100%;
  top: 30%;
  right: 0;
  left: 0;
  position: absolute;
  height: 100%;
  z-index: 9;
}
.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: unset;
  margin: 0 auto;
  animation: spinloader 1.5s linear infinite;
}

.loader .image {
  height: 100px;
  width: 100px;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
