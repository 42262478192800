import DashboardLayout from "@/packages/dashboard/views/DashboardLayout";
import FocusAreas from "@/packages/focus-areas/view/FocusArea";
import ContactCard from "@/packages/contact/components/ContactCard";
import {Auth} from "@/packages/auth";

const ContactRoutes =[
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [
            {
                path: '',
                component: FocusAreas,
                children: [
                    {
                        path: 'contacts',
                        component: ContactCard,
                        name: "ContactCard",
                        meta: { middleware: [Auth]}
                    }
                ]
            }
        ]
    }
];
export default ContactRoutes;
